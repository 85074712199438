<template>
  <main class="login">
    <div v-if="isConfirm">
      <forgetpassword @clicked="loginScreen" :loginName="username"></forgetpassword>
    </div>

    <div class="d-flex" v-else>
      <LoginLeft></LoginLeft>
      <div class="d-flex justify-content-center w-100 px-5">
        <div class="login-right-section">
          <header class="login-header pt-0">
            <nav class="d-flex align-items-center p-relative">
              <ul class="nav nav-tabs p-0 login-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{'active': activeTab==0}" id="signin-tab" type="button" @click="activeTab=0">
                    {{ $t("Sign in") }}
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" :class="{'active': activeTab==1}" id="register-tab" type="button" @click="activeTab=1">
                    {{ $t("Register") }}
                  </button>
                </li>
              </ul>
              <a class="pointer login-header__logo" href="#">
                <img :src="currentLogo" alt />
              </a>
              <div class="d-flex">
                <v-btn
                  class="ml-2"
                  min-width="0"
                  text
                  @click="openSupportModal"
                >
                  <img src="/assets/img/user-headset-dark.svg" alt="" @click="openSupportModal" />
                </v-btn>
                <ChangeLangSelect :isLoginPage="true"></ChangeLangSelect>
              </div>
            </nav>
          </header>

          <div class="login__enter mt-18">
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade" :class="{'active show': activeTab==0}" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                <UserLogin></UserLogin>
              </div>
              <div class="tab-pane fade" :class="{'active show': activeTab==1}" id="register" role="tabpanel" aria-labelledby="register-tab">
                <UserRegister @registerCallBack="toggleTab" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading :active.sync="loggingIn" :can-cancel="false" :is-full-page="true"></loading>
    </div>
    <SupportPopup></SupportPopup>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import forgetpassword from '../ForgetPassword';
import SupportPopup from '@/components/SupportPopup.vue';
import LoginLeft from "@/components/auth/LoginLeft.vue";
import UserRegister from "@/components/auth/UserRegister"
import UserLogin from "@/components/auth/UserLogin.vue";
import ChangeLangSelect from '@/components/ChangeLangSelect'

export default {
  name: 'User_Login',
  components: {
    Loading,
    forgetpassword,
    SupportPopup,
    UserRegister,
    LoginLeft,
    UserLogin,
    ChangeLangSelect
  },
  computed: {
    ...mapState({
      loggingIn: (state) => state.account.loggingIn,
    }),
  },

  data: () => {
    return {
      activeTab: 0,
      username: '',
      password: '',
      isConfirm: false,
    };
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),

    loginScreen(change) {
      this.isConfirm = change;
    },
    openSupportModal() {
      this.$modal.show('supportPopup');
    },
    toggleTab(val) {
      this.activeTab = val;
    },
  },
};
</script>

<style lang="scss">
  .social-button {
    width: 60px;
    height: 60px;
    &.facebook-btn {
      background: url('/assets/img/icons/Facebook.svg');
      background-size: contain;
    }
    &.google-btn {
      background: url('/assets/img/icons/Google-Plus.svg');
      background-size: contain;
    }
    &.twitter-btn {
      background: url('/assets/img/icons/Twitter.svg');
      background-size: contain;
    }
  }
</style>
