<template>
  <div>
    <h3 class="auth_title text-center w-100">{{ $t("Register to Pay Analog") }}</h3>
    <p class="text login__text text-center w-100">{{ $t("Travel, Gift-Card, Bills, Pre-Paid, and much more.") }}</p>
    <section class="registration_form">
      <div class="reseller-info register-reseller__reseller-info">
        <div class="row">
          <div class="col-sm-6 validation-form-group" :class="{
            'form-group--error': $v.newUser_data.first_name.$error,
          }">
            <label class="fs14">
              {{ $t('First name') }} <span class="red--text">*</span>
            </label>
            <input class="input" type="email" :placeholder='$t("First name")' v-model="newUser_data.first_name"
              @input="$v.newUser_data.first_name.$touch" />
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.first_name.$error">
              {{ $t('Invalid value') }}
            </div>
          </div>
          <div class="col-sm-6 validation-form-group" :class="{
            'form-group--error': $v.newUser_data.last_name.$error,
          }">
            <label class="fs14">
              {{ $t('Last name') }} <span class="red--text">*</span>
            </label>
            <input class="input" type="text" :placeholder='$t("Last name")' v-model="newUser_data.last_name"
              @input="$v.newUser_data.last_name.$touch" />
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.last_name.$error">
              {{ $t('Invalid value') }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 validation-form-group" :class="{
            'form-group--error': $v.newUser_data.email.$error,
          }">
            <label class="fs14">
              {{ $t('Email') }} <span class="red--text">*</span>
            </label>
            <input class="input" type="email" :placeholder='$t("Email")' v-model="newUser_data.email"
              @input="$v.newUser_data.email.$touch" />
            <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.email.email">
              {{ $t('Invalid value') }}
            </div>
          </div>
          <div class="col-sm-6 validation-form-group" :class="{
            'form-group--error': $v.newUser_data.phone_number.$error,
          }">
            <label class="fs14">
              {{ $t('Phone number') }} <span class="red--text">*</span>
            </label>
            <input class="input" type="text" :placeholder='$t("Phone number")' v-model="newUser_data.phone_number"
              @input="$v.newUser_data.phone_number.$touch" />
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.phone_number.$error">
              {{ $t('Invalid value') }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label class="fs14">
              {{ $t('Country') }}
            </label>
            <vue-select
              :options="countryList"
              class="input cus-select"
              v-model="newUser_data.country"
            ></vue-select>
          </div>
          <div class="col-sm-6 validation-form-group" :class="{
            'form-group--error': $v.newUser_data.state.$error,
          }">
            <label class="fs14">
              {{ $t('State') }} <span class="red--text">*</span>
            </label>
            <input class="input" type="text" :placeholder='$t("State")' v-model="newUser_data.state"
              @input="$v.newUser_data.state.$touch" />
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.state.$error">
              {{ $t('Invalid value') }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 validation-form-group" :class="{
            'form-group--error': $v.newUser_data.city.$error,
          }">
            <label class="fs14">
              {{ $t('City') }} <span class="red--text">*</span>
            </label>
            <input class="input" type="text" :placeholder='$t("City")' v-model="newUser_data.city"
              @input="$v.newUser_data.city.$touch" />
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.city.$error">
              {{ $t('Invalid value') }}
            </div>
          </div>
          <div class="col-sm-6 validation-form-group" :class="{
            'form-group--error': $v.newUser_data.state.$error,
          }">
            <label class="fs14">
              {{ $t('Street') }} <span class="red--text">*</span>
            </label>
            <input class="input" type="text" :placeholder='$t("Street")' v-model="newUser_data.street_address"
              @input="$v.newUser_data.street_address.$touch" />
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.street_address.$error">
              {{ $t('Invalid value') }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <label class="fs14">
              {{ $t("Display Language") }}
            </label>
            <vue-select
              :options="displayLanguageList"
              class="input cus-select"
              v-model="newUser_data.language_code"
            ></vue-select>
          </div>
          <div class="col-sm-4 validation-form-group" :class="{
            'form-group--error': $v.newUser_data.zip_code.$error,
          }">
            <label class="fs14">
              {{ $t('Zip Code') }} <span class="red--text">*</span>
            </label>
            <input class="input" type="text" :placeholder='$t("Zip Code")' v-model="newUser_data.zip_code"
              @input="$v.newUser_data.zip_code.$touch" />
            <div class="color-red1 fs12 valide-error-msg" v-if="$v.newUser_data.zip_code.$error">
              {{ $t('Invalid value') }}
            </div>
          </div>
        </div>

        <!-- <div class="reseller-info__container d-block mb-10 validation-form-group"
          :class="{ 'form-group--error': $v.newUser_data.city.$error }">
          <label class="fs14">* {{ $t('Address') }}</label>
          <vue-google-autocomplete id="map3" classname="input" placeholder=""
            v-on:placechanged="getAddressDataReseller" :country="['il']" v-model="googleAddressInput"
            @input="$v.newUser_data.city.$touch">
          </vue-google-autocomplete>
          <div class="color-red1 fs12 valide-error-msg" v-if="!$v.newUser_data.city.required">
            {{ $t('This is a required field.') }}
          </div>
        </div> -->
        <div class="reseller-info__container mt-10 mb-10">
          <div class="d-flex">
            <button class="pointer authorisation__button color-white" @click="save()">
              {{ $t('Save') }}
            </button>
            <button class="pointer authorisation__button red__button color-white mx-10" @click="resetForm()">
              {{ $t('Cancel') }}
            </button>
          </div>
        </div>
        
        <p class="text-center color-grey3 my-8">{{ $t("Or register in with") }}</p>

        <div class="d-flex justify-content-center">
          <button class="social-button facebook-btn mx-4"></button>
          <button class="social-button google-btn mx-4"></button>
          <button class="social-button twitter-btn mx-4"></button>
        </div>
        <router-link
          :to="{ name: 'Reseller_Login' }"
        >
            <a class="back-link">{{ $t("Reseller login") }}</a>
          </router-link>
      </div>
    </section>
  </div>
</template>

<script>
import { CountriesConst, DisplayLanguagesConst } from "@/common/constants.js";
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'UserRegister',
  mixins: [validationMixin],
  components: {
  },
  validations: {
    newUser_data: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        email,
        required
      },
      phone_number: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
      street_address: {
        required,
      },
      zip_code: {
        required,
      },
    },
  },
  data: () => {
    return {
      countryList: CountriesConst,
      displayLanguageList: DisplayLanguagesConst,
      newUser_data: {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        country: {code: 'IL', label: 'Israel'},
        state: '',
        city: '',
        street_address: '',
        zip_code: '',
        language_code: {"code": "he", "label": "Hebrew"}
      },
      // googleAddressInput: '',
    };
  },
  methods: {
    ...mapActions('end_user', {
      register: 'register'
    }),

    async save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }

      this.register(this.newUser_data).then(() => {
        this.$emit('registerCallBack', 0);
        this.resetForm();
      });
    },

    resetForm() {
      this.newUser_data = {
        first_name: '',
        last_name: '',
        phone_number: '',
        city: '',
        street: '',
        country: {code: 'IL', label: 'Israel'},
        email: '',
        name: '',
        phone_number: '',
        language_code: {"code": "he", "label": "Hebrew"}
      };
    },
    getAddressDataReseller(address) {
      this.newUser_data.city = address.locality;
      this.newUser_data.street = address.route;
    },
  },
};
</script>

<style lang="scss" scoped>
.red--text {
  color: #F44336;
}

.authorisation__button {
  width: 130px;
}

@media screen and (max-width: 720px) {
  .back-link {
    margin-top: 20px;
  }
}
</style>
