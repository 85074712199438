<template> 
    <main class="enterNumber">
        <div v-if="enterNumber">
            <h1 class="title login__title">Log-in</h1>
            <div class="login__enter">
                <h3 class="title title__h3 text-center">{{ $t("Password recovery") }}</h3>
                <p class="text login__text text-center">{{ $t("In order to recover a password, enter the following phone number:") }}</p>
                <p class="text login__text text-center">{{ this.suffixPhone }}*******</p>
                <form @submit.prevent="handleSubmit" class="authorisation login__authorisation">
                    <div class="authorisation__name">
                    <label class="label" for="number">{{ $t("Phone number") }}:</label>
                    <input
                        id="number"
                        v-model="phonenumber"
                        value=""
                        class="input authorisation__input"
                        type="text"
                    />
                    </div> 
                    <a
                    class="link authorisation__button color-white mt-15"
                    @click="onHandleApprove"
                    >{{ $t("Approval") }}</a>
                </form>
            </div>
        </div>
        <div v-else>
            <div v-if="code!==1">
                <div class="enterCode">
                    <h3 class="title title__h3 text-center">{{ $t("Password recovery") }}</h3>
                    <p class="text login__text text-center">{{ $t("Enter the verification code that appears in the message") }}</p>
                    <form @submit.prevent="handleSubmit" class="authorisation login__authorisation">
                        <div class="authorisation__name">
                        <label class="label " for="number">{{ $t("Verification code") }}</label>
                        <input
                            id="verificationCode"
                            v-model="verification_code"
                            value=""
                            class="input authorisation__input"
                            type="text"
                        />
                        </div> 
                        
                        <a
                        class="link authorisation__button color-white mt-15"
                        @click="onHandleVerificationCode"
                        >{{ $t("Approval") }}</a>
                    </form>
                </div>
            </div>
            <div v-if="code===1">
                <div class="enterPassword">
                    <h3 class="title title__h3 text-center">{{ $t("Password recovery") }}</h3>
                    <p class="text login__text text-center">{{ $t("Enter the verification code that appears in the message") }}</p>
                    <form @submit.prevent="handleSubmit" class="authorisation login__authorisation">
                        <div class="authorisation__name">
                        <label class="label " for="number">{{ $t("Enter a new password") }}</label>
                        <input
                            id="newPassword"
                            v-model="new_password"
                            value=""
                            class="input authorisation__input"
                            type="text"
                        />
                        </div>
                        <a
                        class="link authorisation__button color-white mt-15"
                        @click="onHandleNewPassword"
                        >{{ $t("Approval") }}</a>
                    </form>
                </div>
            </div>
        </div>
        
  </main>
</template>

<script>
    import {mapState, mapActions, mapGetters} from 'vuex'
    import appConfig from "../appConfig";
    import axios from "axios";

    export default {
        props: [
            'loginName',
        ],
        data: function() {
            return{
                phonenumber: "",
                verification_code:"",
                new_password:"",
            }
        },
        computed: {
            ...mapState({
                suffixPhone: (state) => state.reset_module.suffixPhone,
                code: (state) => state.reset_module.code,
                enterNumber: (state) => state.reset_module.enterNumber,
                verificationCode: (state) => state.reset_module.verificationCode,
                newPassword: (state) => state.reset_module.newPassword
            }),
        },
        created() {
            this.changeSuffix({loginName: this.loginName})
        },
        methods: {
            ...mapActions('reset_module', {
                changeSuffix: 'changeSuffix',
                handleApprove: 'handleApprove',
                handleVerificationCode: 'handleVerificationCode',
                handleNewPassword: 'handleNewPassword'
            }),

            onHandleApprove() {
                this.handleApprove({loginName: this.loginName, phone_number: this.phonenumber})
            },
            onHandleVerificationCode() {
                this.handleVerificationCode({loginName: this.loginName, verificationCode: this.verification_code})
            },
            onHandleNewPassword() {
                this.handleNewPassword({loginName: this.loginName, verificationCode: this.verification_code, newPassword: this.new_password});
                this.$emit('clicked', false)
            }
         },
        
    }
        
        
</script>

<style>
.approve_number{
    cursor: pointer
}
</style>

