var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"auth_title text-center w-100"},[_vm._v(_vm._s(_vm.$t("Register to Pay Analog")))]),_c('p',{staticClass:"text login__text text-center w-100"},[_vm._v(_vm._s(_vm.$t("Travel, Gift-Card, Bills, Pre-Paid, and much more.")))]),_c('section',{staticClass:"registration_form"},[_c('div',{staticClass:"reseller-info register-reseller__reseller-info"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.newUser_data.first_name.$error,
        }},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('First name'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser_data.first_name),expression:"newUser_data.first_name"}],staticClass:"input",attrs:{"type":"email","placeholder":_vm.$t("First name")},domProps:{"value":(_vm.newUser_data.first_name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newUser_data, "first_name", $event.target.value)},_vm.$v.newUser_data.first_name.$touch]}}),(_vm.$v.newUser_data.first_name.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.newUser_data.last_name.$error,
        }},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('Last name'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser_data.last_name),expression:"newUser_data.last_name"}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t("Last name")},domProps:{"value":(_vm.newUser_data.last_name)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newUser_data, "last_name", $event.target.value)},_vm.$v.newUser_data.last_name.$touch]}}),(_vm.$v.newUser_data.last_name.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.newUser_data.email.$error,
        }},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('Email'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser_data.email),expression:"newUser_data.email"}],staticClass:"input",attrs:{"type":"email","placeholder":_vm.$t("Email")},domProps:{"value":(_vm.newUser_data.email)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newUser_data, "email", $event.target.value)},_vm.$v.newUser_data.email.$touch]}}),(!_vm.$v.newUser_data.email.email)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.newUser_data.phone_number.$error,
        }},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('Phone number'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser_data.phone_number),expression:"newUser_data.phone_number"}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t("Phone number")},domProps:{"value":(_vm.newUser_data.phone_number)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newUser_data, "phone_number", $event.target.value)},_vm.$v.newUser_data.phone_number.$touch]}}),(_vm.$v.newUser_data.phone_number.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('Country'))+" ")]),_c('vue-select',{staticClass:"input cus-select",attrs:{"options":_vm.countryList},model:{value:(_vm.newUser_data.country),callback:function ($$v) {_vm.$set(_vm.newUser_data, "country", $$v)},expression:"newUser_data.country"}})],1),_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.newUser_data.state.$error,
        }},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('State'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser_data.state),expression:"newUser_data.state"}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t("State")},domProps:{"value":(_vm.newUser_data.state)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newUser_data, "state", $event.target.value)},_vm.$v.newUser_data.state.$touch]}}),(_vm.$v.newUser_data.state.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.newUser_data.city.$error,
        }},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('City'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser_data.city),expression:"newUser_data.city"}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t("City")},domProps:{"value":(_vm.newUser_data.city)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newUser_data, "city", $event.target.value)},_vm.$v.newUser_data.city.$touch]}}),(_vm.$v.newUser_data.city.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()]),_c('div',{staticClass:"col-sm-6 validation-form-group",class:{
          'form-group--error': _vm.$v.newUser_data.state.$error,
        }},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('Street'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser_data.street_address),expression:"newUser_data.street_address"}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t("Street")},domProps:{"value":(_vm.newUser_data.street_address)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newUser_data, "street_address", $event.target.value)},_vm.$v.newUser_data.street_address.$touch]}}),(_vm.$v.newUser_data.street_address.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t("Display Language"))+" ")]),_c('vue-select',{staticClass:"input cus-select",attrs:{"options":_vm.displayLanguageList},model:{value:(_vm.newUser_data.language_code),callback:function ($$v) {_vm.$set(_vm.newUser_data, "language_code", $$v)},expression:"newUser_data.language_code"}})],1),_c('div',{staticClass:"col-sm-4 validation-form-group",class:{
          'form-group--error': _vm.$v.newUser_data.zip_code.$error,
        }},[_c('label',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm.$t('Zip Code'))+" "),_c('span',{staticClass:"red--text"},[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newUser_data.zip_code),expression:"newUser_data.zip_code"}],staticClass:"input",attrs:{"type":"text","placeholder":_vm.$t("Zip Code")},domProps:{"value":(_vm.newUser_data.zip_code)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.newUser_data, "zip_code", $event.target.value)},_vm.$v.newUser_data.zip_code.$touch]}}),(_vm.$v.newUser_data.zip_code.$error)?_c('div',{staticClass:"color-red1 fs12 valide-error-msg"},[_vm._v(" "+_vm._s(_vm.$t('Invalid value'))+" ")]):_vm._e()])]),_c('div',{staticClass:"reseller-info__container mt-10 mb-10"},[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"pointer authorisation__button color-white",on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]),_c('button',{staticClass:"pointer authorisation__button red__button color-white mx-10",on:{"click":function($event){return _vm.resetForm()}}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")])])]),_c('p',{staticClass:"text-center color-grey3 my-8"},[_vm._v(_vm._s(_vm.$t("Or register in with")))]),_vm._m(0),_c('router-link',{attrs:{"to":{ name: 'Reseller_Login' }}},[_c('a',{staticClass:"back-link"},[_vm._v(_vm._s(_vm.$t("Reseller login")))])])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"social-button facebook-btn mx-4"}),_c('button',{staticClass:"social-button google-btn mx-4"}),_c('button',{staticClass:"social-button twitter-btn mx-4"})])
}]

export { render, staticRenderFns }