<template>
    <div class="login-left">
        <h2 class="auth-side_title">{{ $t("Join the revolution Making digital products accessible in a local store") }}</h2>
        <div class="d-flex justify-content-between mt-8">
            <div class="desc">
                <p>
                    {{ $t("Travel Gift-Card, Bills, Pre-Paid and much more.") }}
                </p>
                <p>{{ $t("See the video for a quick sneak pick") }}</p>
            </div>
            <button type="button" class="btn mt-10 mr-5" @click="openVideo">
                <img class="img-fluid" src="/assets/img/play-btn.png">
                <p>
                    {{ $t("Watch Demo") }}
                </p>
            </button>
        </div>
        <div class="grid-parent mt-4">
            <div class="div1">
                <img class="img-fluid h-100" src="/assets/img/login-icons/travel.png">
            </div>
            <div class="div2">
                <img class="img-fluid px-8" src="/assets/img/login-icons/netflix.png">
            </div>
            <div class="div3">
                <img class="img-fluid" src="/assets/img/login-icons/itunes.png">
            </div>
            <div class="div4">
                <img class="img-fluid" src="/assets/img/login-icons/xbox.png">
            </div>
            <div class="div5">
                <img class="img-fluid px-5" src="/assets/img/login-icons/playstation.png">
            </div>
            <div class="div6">
                <img class="img-fluid" src="/assets/img/login-icons/amazon.png">
            </div>
            <div class="div7">
                <img class="img-fluid" src="/assets/img/login-icons/nordvpn.png">
            </div>
            <div class="div8">
                <img class="img-fluid" src="/assets/img/login-icons/razer.png">
            </div>
            <div class="div9">
                <img class="img-fluid px-8" src="/assets/img/login-icons/fortnite.png">
            </div>
            <div class="div10">
                <img class="img-fluid" src="/assets/img/login-icons/pubg.png">
            </div>
            <div class="div11">
                <img class="img-fluid px-8" src="/assets/img/login-icons/ea-play.png">
            </div>
            <div class="div12">
                <img class="img-fluid" src="/assets/img/login-icons/steam.png">
            </div>
            <div class="div13">
                <img class="img-fluid" src="/assets/img/login-icons/microsoft.png">
            </div>
            <div class="div14">
                <img class="img-fluid" src="/assets/img/login-icons/minecraft.png">
            </div>
            <div class="div15">
                <img class="img-fluid px-10" src="/assets/img/login-icons/ofc.png">
            </div>
            <div class="div16">
                <img class="img-fluid" src="/assets/img/login-icons/karma-coin.png">
            </div>
        </div>
        <modal-video :channel="videoChannel" :videoId="videoId" :isOpen.sync="videoIsOpen" />
    </div>
</template>

<script>
import ModalVideo from "../ModalVideo.vue"
export default {
    name: "LoginLeft",
    components: {
        ModalVideo
    },
    data: () => {
        return {
            videoIsOpen: false
        }
    },
    methods: {
        openVideo() {
            this.videoIsOpen = !this.videoIsOpen
        }
    },
    computed: {
        videoChannel: function () {
            return 'youtube'
        },
        videoId: function () {
            return 'RX3tx80wEfo'
        }
    }
}
</script>

<style lang="scss" scoped>
.login-left {
    position: sticky;
    top: 0;
    padding: 20px;
    background: #D6E2E2;
    width: 100%;
    max-width: 657px;
    height: 100vh;
    padding-top: 60px;
    z-index: 1;
    @media screen and (max-width: 992px) {
        display: none;
    }
    .auth-side_title {
        font-weight: 700;
        font-size: 40px;
        max-width: 530px;
        line-height: 1.5;
    }
    .desc p {
        font-size: 14px;
        margin-bottom: 0px;
        opacity: 0.6;
    }
    .btn {
        display: flex;
        align-items: center;
        background-color: #002036;
        padding: 0px;
        font-size: 14px;
        height: 56px;
        border-radius: 36px;
        img {
            margin: 0 10px;
        }
        p {
            margin: 0 10px 0 0;
            color: #fff;
        }
    }

    .grid-parent {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 6px;
        grid-row-gap: 6px;
        &>div {
            background-color: #fff;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            .img {
                object-fit: contain;
            }
        }
    }
    
    .div1 { grid-area: 1 / 1 / 4 / 2; }
    .div2 { grid-area: 1 / 2 / 2 / 3; }
    .div3 { grid-area: 1 / 3 / 2 / 4; }
    .div4 { grid-area: 2 / 2 / 3 / 3; }
    .div5 { grid-area: 2 / 3 / 3 / 4; }
    .div6 { grid-area: 3 / 2 / 4 / 3; }
    .div7 { grid-area: 3 / 3 / 4 / 4; }
    .div8 { grid-area: 4 / 1 / 5 / 2; }
    .div9 { grid-area: 4 / 2 / 5 / 3; }
    .div10 { grid-area: 4 / 3 / 5 / 4; }
    .div11 { grid-area: 5 / 1 / 6 / 2; }
    .div12 { grid-area: 5 / 2 / 6 / 3; }
    .div13 { grid-area: 5 / 3 / 6 / 4; }
    .div14 { grid-area: 6 / 1 / 7 / 2; }
    .div15 { grid-area: 6 / 2 / 7 / 3; }
    .div16 { grid-area: 6 / 3 / 7 / 4; }
}

.rtl-type {
    .login-left .btn p {
        margin: 0 0 0 10px;
    }
}

</style>