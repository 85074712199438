<template>
  <div>
    <h3 class="auth_title w-100 text-center">{{ $t("Sign in to Pay Analog") }}</h3>
    <p class="text login__text text-center w-100">{{ $t("Travel, Gift-Card, Bills, Pre-Paid, and much more.") }}</p>
    <form @submit.prevent="handleSubmit" class="authorisation login__authorisation">
      <div class="authorisation__name">
        <label class="label" for="username">{{ $t("Username") }}</label>
        <div class="input-group">
          <input id="username" v-model="username" v-model.lazy="$v.username.$model"
            class="form-control input authorisation__input" :class="{ ' validation-error': $v.username.$error }"
            type="text" @keyup.enter="onEnter('uname')" />
          <span class="input-group-text"><i class="far fa-envelope"></i></span>
        </div>
      </div>

      <div class="authorisation__password">
        <label class="label" for="password">{{ $t("Password") }}</label>
        <div class="input-group">
          <input id="password" v-model="password" v-model.lazy="$v.password.$model"
            class="form-control input authorisation__input" :class="{ ' validation-error': $v.password.$error }"
            type="password" @keyup.enter="onEnter('upass')" />
          <span class="input-group-text" style="Font Awesome 6 Pro">
            <img src="/assets/img/lock.png">
          </span>
        </div>
      </div>

      <div class="authorisation__remember">
        <a class="pointer authorisation__forgot" @click="changeConfirm">
          {{ $t("Forgot my Password?") }}
        </a>
      </div>

      <a class="pointer authorisation__button color-white mb-3 mt-15" @click="handleSubmit">
        {{ $t("Sign in") }}
      </a>

      <p class="text-center color-grey3 my-8">{{ $t("Or sign in with") }}</p>

      <div class="d-flex justify-content-center">
        <button class="social-button facebook-btn mx-4"></button>
        <button class="social-button google-btn mx-4"></button>
        <button class="social-button twitter-btn mx-4"></button>
      </div>
      <div class="login-footer d-flex align-items-center justify-content-between">
        <router-link :to="{ name: 'Reseller_Login' }">
          <a class="back-link">{{ $t("Reseller login") }}</a>
        </router-link>
        <div class="login-contact-block df-c fs14 pointer" @click="openSupportModal">
          <div class="contact-btn df-c mx10">
            <img src="/assets/img/user-headset-light.svg" alt="" />
          </div>
          <span> {{ $t("Support") }}</span>
        </div>
      </div>
    </form>
    <Modal2FA ref="modal2FARef" userType="EndUser"></Modal2FA>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, email, sameAsData } from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import forgetpassword from '@/views/ForgetPassword';
import Modal2FA from "@/components/auth/Modal2FA.vue";

export default {
  mixins: [validationMixin],
  name: 'UserLogin',
  components: {
    Loading,
    forgetpassword,
    Modal2FA
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.end_user.isLoggedIn
    }),
  },

  data: () => {
    return {
      username: '',
      password: '',
      isConfirm: false,
    };
  },
  validations: {
    username: {
      required,
    },
    password: {
      required,
    },
  },
  methods: {
    ...mapActions('end_user', ['login']),
    async handleSubmit() {
      let obj = this;
      obj.$v.$touch();
      if (obj.$v.$invalid) {
        return;
      }
      const { username, password } = obj;
      if (username && password) {
        this.login({ username, password }).then(
          async (res) => {
            if (obj.isLoggedIn) {
              try {
                localStorage.setItem("user_type_logged_in", "EndUser");
                await obj.$router.push(obj.$route.query.redirect || '/enduser/home');
              } catch (e) { }
            } else if (res.message.title == "Check 2fa") {
              obj.openModal2FA();
            }
            return res;
          },
          (error) => {
            obj.password = '';
            return error;
          },
        );
      }
    },
    changeConfirm() {
      this.isConfirm = true;
    },
    onEnter(formType) {
      if (formType == 'uname' && this.password == '') {
        document.getElementById('password').focus();
      } else if (formType == 'upass' && this.username == '') {
        document.getElementById('username').focus();
      }

      if (this.username != '' && this.password != '') {
        this.handleSubmit();
      }
    },
    openSupportModal() {
      this.$modal.show('supportPopup');
    },
    openModal2FA() {
      this.$refs.modal2FARef.openModal();
    }
  },
};
</script>

<style lang="scss" scoped>
.authorisation {
  text-align: unset;
}

.login-footer {
  margin-top: 20px;

  .login-contact-block {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .login-footer {
    margin-top: 50px;

    .login-contact-block {
      display: flex;
      margin-right: 0;
    }
  }
}
</style>